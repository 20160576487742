.modal {
  position: absolute;
  top: 1rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.close_button {
  background-color: transparent;
  box-shadow: none !important;
  color: black;
  position: absolute;
  top: 0.75rem;
  right: 0;
}

.close_button:hover {
  background-color: transparent;
}

.table_cell_bold {
  font-weight: bold;
  font-size: 12px;
  border: none !important;
}

.table_cell {
  border: none !important;
}
