.stack {
  align-items: center;
  justify-content: center;
}

.stack__item,
.stack__item__all {
  cursor: pointer;
  transition: all 0.3s ease;
}

.stack__item {
  color: var(--main-color-syndark);
  background-color: transparent;
}

.stack__close {
  position: absolute;
  right: 0;
}

.stack__item__text {
  font-size: 0.9rem;
}

.stack__item:hover > .stack__item__text {
  font-weight: bold;
}

.stack__item__text_active {
  font-weight: bold;
}

.stack__subcategories .stack__item__text {
  font-size: 0.8rem;
}

.stack__subcategories {
  padding: 0.4rem;
  width: 80vw;
  border-radius: 4px;
  background-color: #fff;
  z-index: 9999;
  margin-top: -5px;
  background-color: transparent;
  display: contents;
}

@media (max-width: 1024px) {
  .stack__item__all {
    width: 100vw;
  }

  .stack__subcategories {
    z-index: 10;
    width: 80vw;
  }
}

@media (max-width: 450px) {
  .stack__item__all {
    display: none;
  }
}
