.logo {
  width: 250px;
  z-index: 80;
  cursor: pointer;
  transform: translateY(-20px) translateX(-10px)
}

@media (max-width: 1024px) {
  .logo {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .logo {
    width: 180px;
  }
}
