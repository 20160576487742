.header {
  color: var(--main-color-medium);
  position: relative;
  margin-bottom: 0.2rem;
  z-index: 9999;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
}

@media (max-width: 450px) {
  .header {
    position: fixed;
    bottom: 0px;
    left: 0px;
    margin: 0px;
    z-index: 999999999;
    background-color: #fff;
    width: 100%;
  }

  .container__header__up span {
    max-width: 150px;
  }

  .categories__mobile {
    position: fixed;
    transform: translateY(-100%);
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    z-index: 99;
    transition: 300ms all ease-in-out;
    padding: 0 5%;
  }

  .categories__mobile.expanded {
    position: fixed;
    transform: translateY(0%);
    padding-top: 100px;
    z-index: 99;
    transition: 300ms all ease-in-out;
  }
}
