.footer {
  display: flex;

  position: relative;
  bottom: 0;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 1rem;

  width: 100%;
}

.footer__links {
  align-self: center
}

.footer__trustseal {
  padding-top: 5%;
}

.footer__ecommerce {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer__legal,
.footer__social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}

.footer__social {
  margin-bottom: 8px;
  gap: 25px;
}

.footer__legal .link,
.footer__social .link {
  font-size: 0.7rem;
  padding-top: 3px;
  padding-left: 5px;
}

.footer__social .instagram {
  width: 20px;
  height: 20px;
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  color: whitesmoke;
  border-radius: 20%;
}

.footer__social .youtube {
  width: 20px;
  height: 20px;
  color: #ff0000;
  border-radius: 20%;
}

.footer__social .x {
  width: 20px;
  height: 20px;
  color: #000000;
  border-radius: 20%;
}

.footer__social .facebook {
  width: 20px;
  height: 20px;
  color: #036de4;
  border-radius: 20%;
}

.footer__badges {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  width: 22%;
  align-items: center;
  align-self: center;
}

.footer__badges .title {
  text-align: center;
  margin-left: -10px;
  font-size: 0.8rem;
  width: 100%;
}

.badge {
  height: min-content;
}

@media (max-width: 720px) {
  .footer {
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem;
    gap: 2rem;
  }

  .footer__ecommerce,
  .footer__badges,
  .footer__legal,
  .footer__social {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .social:hover {
    color: var(--main-color-synblue);
  }

  .footer__social {
    gap: 0.2rem;
  }

  .link {
    margin: 0;
    font-size: 0.8rem;
  }
}

@media (max-width: 450px) {
  .footer {
    display: none;
  }
}
