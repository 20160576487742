.custom__order {
  width: 100%;
}
.button__custom__order {
  background-color: #272727;
  color: #fff;
  font-weight: bold !important;
  border-radius: 0px;
  width: 100%;
}

.stack__item__text {
  color: #272727;
}

.menu__accordion {
  border: 0px;
  box-shadow: none;
}

.menu__accordion::before {
  content: none;
}

.menu__accordion__details {
  padding: 2% 10%;
}

.menu__mobile__accordion__details {
  margin-top: 3%;
  padding: 0rem 1rem;
}

.categories__mobile__father {
  max-height: 75vh;
  overflow: scroll;
  z-index: 105;
}

.categories__mobile__father::-webkit-scrollbar {
  display: none;
}

.menu__mobile__buttons {
  padding-left: 1rem;
  margin: 15px;
}
