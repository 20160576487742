.nav {
  margin-right: 2rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 999;
  background-color: #fff;
}

.navbar__icons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.nav__button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 0.5rem;
  margin: 0 0.5rem;
  color: var(--main-color-medium);
  background-color: transparent;
}
.nav__button:hover {
  background-color: var(--main-color-light);
}

.nav__button__icon {
  font-size: 2rem;
}

.nav__button__icon__auth {
  font-size: 2rem;
  border-radius: 100%;
  color: var(--main-color-syngreen-dark);
}

.nav__button__icon__authenticated {
  position: absolute;
  bottom: 8px;
  right: 6px;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: bold;
  color: var(--main-color-light);
  background-color: var(--main-color-syngreen-dark);
}

.nav__button__icon__notifications {
  position: absolute;
  bottom: 8px;
  right: 6px;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: bold;
  color: var(--main-color-light);
  background-color: var(--main-color-syngreen-dark);
}

@media (max-width: 700px) {
  .nav__button__icon {
    font-size: 1.8rem;
  }

  .nav__button__icon__auth {
    font-size: 1.8rem;
    border-radius: 100%;
    color: var(--main-color-syngreen-dark);
  }

  .nav__button__icon__authenticated {
    font-size: 0.8rem;
  }

  .nav__button__icon__notifications {
    font-size: 0.8rem;
  }

  .nav {
    margin-right: 0.2rem;
  }
}

@media (max-width: 450px) {
  .container {
    position: fixed;
    bottom: 0px;
    left: 0px;
    margin: 0px;
    z-index: 999999999;
    background-color: #fff;
    width: 100%;
  }

  .help__button {
    display: none;
  }

  .nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .nav__button__icon {
    stroke: #fff;
    stroke-width: 1px;
  }

  .navbar__buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0px -10px 10px rgb(0 0 0 / 8%);
  }

  .nav__button {
    color: grey;
  }

  .nav__button__text {
    font-size: 1.2rem;
    font-weight: lighter;
  }
}
