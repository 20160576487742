.button {
  border: none;
  border-bottom: 2px solid var(--main-color-medium);
  color: var(--main-color-medium);
  transition: all 0.3s ease-in-out;

  position: relative;
}

.button:hover {
  border: none;
  color: var(--main-color-synblue);
  border-bottom: 2px solid var(--main-color-synblue);
}
