/* TODO
* Check if it is necessary to make a scroll from main section
*/
@media (max-width: 450px) {
}


.carrousel_banner {
    margin-left: 85px;
    margin-right: 85px; 
    width: calc(100% - 170px);
}


@media (max-width: 1025px) {
    .carrousel_banner {
        margin-left: 65px;
        margin-right: 65px; 
        width: calc(100% - 130px);
    }
}
  