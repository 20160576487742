.menu__history {
  display: flex;
  justify-content: flex-start;
  margin-left: 0.5rem;
}

.menu__history p {
  max-width: 100vw;
  margin-left: 0.5rem;
  font-weight: 700;
  text-align: left;
  color: #272727;
}

.menu__history svg {
  color: #272727;
}
